import * as IS from "./validator";
/**
 * Display a POPUP on page, accepted params are:
 * 
 * - Number statusCode >= 200 if given set the HTTP StatusCode, else use elem.statusCode if available
 * - Number autodestroy < 200 if given set the autodestroy countdown
 * - Object.objectURL if given add a DOWNLOAD button
 * - Object.download if given add JSON DOWNLOAD button
 * - Object.pre if given add VIEW PRE button
 * - String "enlarged" display Popup enlarged
 * - String "showPre" display the first PRE content instead of SPAN
 * 
 * @param {*} elem - Content to display, Error, Object or String
 * 
 */
export default function Popup(elem) {
    const randomId = `popup-${Math.floor(Math.random() * 5000)}`;
    let countdownInterval = undefined;
    function handleKeyDown(event) {
        if (event.key === "Escape") closePopup();
    }
    function closePopup() {
        document.getElementById(randomId).remove(); 
        document.removeEventListener("keydown", handleKeyDown);
        if (countdownInterval) clearInterval(countdownInterval);
    }
    function enlargePopup() {
        try {
            const popupClass = document.getElementById(randomId).classList;
            if (popupClass && popupClass.contains("enlargedPopup")) popupClass.remove("enlargedPopup")
            else if (popupClass) popupClass.add("enlargedPopup");
        } catch(err) {
            console.log(err);
        }
    }
    function viewPre(event, el) {
        try {
            const popup = document.getElementById(randomId);
            if (popup) {
                const pre = popup.querySelector("pre");
                const span = popup.querySelector("span");
                if (event.target.value === "hide") {
                    event.target.value = "show";
                    event.target.innerHTML = "HIDE";
                    if (span) span.style.display = "none";
                    if (pre) {
                        pre.innerHTML = el.pre;
                        pre.style.display = "";
                    }
                } else {
                    event.target.value = "hide";
                    event.target.innerHTML = el.label || "VIEW";
                    if (span) span.style.display = "";
                    if (pre) {
                        pre.style.display = "none";
                        pre.innerHTML = "";
                    }
                }
            }
        } catch(e) {
            console.log(e);
        }
    }

    let status = 0;
    let autodestroy;
    let buttons = [];
    let bgColor = "rgba(0, 255, 255, 0.7)"; 
    let text = "";
    let enlarged;
    let showPre;
    let moreText = [];
    let title;

    if (IS.object(elem) && IS.statusCode(elem.statusCode)) status = Number(elem.statusCode);

    if (arguments.length > 1) {
        for (let i=1; i<arguments.length; i++) {
            if (IS.number(arguments[i]) && arguments[i] < 200) autodestroy = arguments[i];
            else if (IS.statusCode(arguments[i])) status = arguments[i];
            else if (IS.object(arguments[i]) && arguments[i].objectURL) buttons.push(arguments[i]); 
            else if (IS.object(arguments[i]) && typeof arguments[i].pre !== "undefined") {
                let txt = "";
                if (IS.string(arguments[i].pre)) txt = arguments[i].pre;
                else if (IS.object(arguments[i].pre)) txt = JSON.stringify(arguments[i].pre, null, 2);
                else if (IS.array(arguments[i].pre)) txt = JSON.stringify(arguments[i].pre, null, 2);
                buttons.push({ ...arguments[i], pre: txt });
            }
            else if (IS.object(arguments[i]) && arguments[i].download) buttons.push(arguments[i]); 
            else if (IS.string(arguments[i]) && arguments[i] === "enlarged") enlarged = true; 
            else if (IS.string(arguments[i]) && arguments[i] === "showPre") showPre = true; 
            else if (IS.string(arguments[i])) moreText.push(arguments[i]); 
        }
    }

    if (IS.error(elem)) {
        console.log(elem);
        title = "ERROR";
        text = elem.message;
        if (!status && IS.number(elem.status)) status = elem.status;
        else if (!status) status = 500;
    } else if (IS.object(elem)) {   
        if (IS.string(elem.title)) title = elem.title; 
        if (IS.string(elem.html)) text = elem.html;
        else if (IS.string(elem.result)) text = elem.result;
        else if (IS.string(elem.message)) text = elem.message;
        else {
            for (const [key, val] of Object.entries(elem)) {
                if (IS.string(val)) text += `${key}: ${val}<br>`;
                else if (key !== "statusCode" && IS.number(val)) text += `${key}: ${val}<br>`;
                else if (IS.boolean(val)) text += `${key}: ${val}<br>`;
            }            
        }
    } else if (IS.string(elem)) title = elem;   

    if (IS.object(elem)) {
        const f = new Blob([JSON.stringify(elem, null, 2)], { type: "application/json" });
        const h = URL.createObjectURL(f);
        buttons.push({ objectURL: h, filename: "response" });
        buttons.push({ pre: JSON.stringify(elem, null, 2) });
    }
    
    if (!status) status = 200;
    if (status === 200) bgColor = "rgba(0, 255, 0, 0.7)";
    else if (status === 202) bgColor = "rgba(0, 255, 128, 0.7)";
    else if (status === 404) bgColor = "rgba(255, 255, 0, 0.7)";
    else if ((status >= 300)&&(status < 400)) bgColor = "rgba(0, 0, 255, 0.7)";
    else if ((status >= 400)&&(status < 500)) bgColor = "rgba(255, 128, 0, 0.7)";
    else if ((status >= 500)&&(status < 600)) bgColor = "rgba(255, 0, 0, 0.7)";

    if (autodestroy) {
        text += `<br><br><br>Closing in <span id='countdown-${randomId}'>${parseInt(autodestroy)}</span>s`;
        countdownInterval = setInterval(() => {
            let seconds = parseInt(document.getElementById(`countdown-${randomId}`).innerHTML) - 1;
            if (seconds > 0) document.getElementById(`countdown-${randomId}`).innerHTML = seconds;
            else closePopup();
        }, 1000);
    } 

    const span = document.createElement("span");
    span.innerHTML = text;
    span.style.display = "";

    if (moreText.length) {
        const p = document.createElement("p");
        p.innerHTML = moreText.join("<br>");
        span.appendChild(p);
    }

    const pre = document.createElement("pre");
    pre.style.display = "none";

    const popupStatus = document.createElement("label");
    popupStatus.innerHTML = status;
    popupStatus.className = "popupStatus";

    const closeImage = document.createElement("img");
    closeImage.src = "/img/close.png";
    closeImage.style.right = "1em";
    closeImage.onclick = () => { closePopup() };   
    const enlargeImage = document.createElement("img");
    enlargeImage.src = "/img/enlarge.png";
    enlargeImage.style.left = "1em";
    enlargeImage.onclick = () => { enlargePopup() };  

    const popupContainer = document.createElement("div");
    popupContainer.className = "popup hithere";
    if (enlarged) popupContainer.classList.add("enlargedPopup");
    popupContainer.style.backgroundColor = bgColor;
    popupContainer.id = randomId

    popupContainer.appendChild(closeImage);
    popupContainer.appendChild(enlargeImage);
    popupContainer.appendChild(popupStatus);

    if (title) {
        const h3 = document.createElement("h3");
        h3.innerHTML = title;
        popupContainer.appendChild(h3);
    }

    popupContainer.appendChild(span);
    popupContainer.appendChild(pre);

    if (buttons.length) {
        if (showPre) {
            const index = buttons.findIndex(e => typeof e.pre !== "undefined");
            if (index !== -1) {
                pre.style.display = "";
                pre.innerHTML = buttons[index].pre;
                span.style.display = "none";
                buttons[index].value = "show";
            }
        }
        const line = document.createElement("line");
        for (const btn of buttons) {
            try {
                if (btn.objectURL) {
                    const a = document.createElement("a");
                    a.setAttribute("href", btn.objectURL);
                    a.setAttribute("download", `${btn.filename || "file"}.${btn.ext || "json"}`);
                    const button = document.createElement("button");
                    button.innerHTML = btn.label || "DOWNLOAD";                    
                    a.appendChild(button);
                    line.appendChild(a);
                } else if (btn.pre) {
                    const button = document.createElement("button");
                    button.innerHTML = btn.label || "VIEW";
                    if (btn.value) button.innerHTML = "HIDE";
                    button.value = btn.value || "hide";
                    button.onclick = (event) => { viewPre(event, btn) };   
                    line.appendChild(button);
                } else if (btn.download) {
                    const f = new Blob([JSON.stringify(btn.download, null, 2)], { type: "application/json" });
                    const h = URL.createObjectURL(f);
                    const a = document.createElement("a");
                    a.setAttribute("href", h);
                    a.setAttribute("download", `${btn.filename || "file"}.${btn.ext || "json"}`);
                    const button = document.createElement("button");
                    button.innerHTML = btn.label || "JSON";                    
                    a.appendChild(button);
                    line.appendChild(a);
                }
            } catch(e) {
                console.log(e);
            }
        }
        popupContainer.appendChild(line);
    }    

    const parentElement = document.getElementById("root");
    if (parentElement) parentElement.appendChild(popupContainer);

    document.addEventListener("keydown", handleKeyDown);
}
