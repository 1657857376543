import * as IS from "./validator";

function Confirm(text) {
    try {
        let handleResponse;
        let moreText = [];
    
        if (arguments.length > 1) {
            for (let i=1; i<arguments.length; i++) {
                if (IS.string(arguments[i])) moreText.push(arguments[i]); 
                else if (IS.func(arguments[i])) handleResponse = arguments[i];
            }
        }
    
        function handleKeyDown(event) {
            if (event.key === "Enter") {
                if (IS.func(handleResponse)) handleResponse(true); 
                closeConfirm();
            }
            if (event.key === "Escape") {
                if (IS.func(handleResponse)) handleResponse(false);
                closeConfirm();
            }
        }
        function closeConfirm() {
            document.getElementById("confirmContainer").remove(); 
            document.removeEventListener("keydown", handleKeyDown);
        }

        const confirmBox = document.createElement("div");
        confirmBox.className = "confirm";
    
        const message = document.createElement("h2");
        message.innerHTML = text;
        confirmBox.appendChild(message);

        if (IS.array(moreText, true)) {
            const span = document.createElement("span");
            span.innerHTML = moreText.join("\n");
            span.style.fontSize = "1.2em";
            confirmBox.appendChild(span);
        }

        const line = document.createElement("line"); 
        if (IS.func(handleResponse)) {
            const buttonYes = document.createElement("button");
            buttonYes.innerHTML = "SI";
            buttonYes.onclick = () => { handleResponse(true); closeConfirm(); };
            const buttonNo = document.createElement("button");
            buttonNo.innerHTML = "NO";
            buttonNo.onclick = () => { handleResponse(false); closeConfirm() };    
            line.appendChild(buttonYes);
            line.appendChild(buttonNo);
        } else {
            const buttonOk = document.createElement("button");
            buttonOk.innerHTML = "OK";
            buttonOk.onclick = () => { closeConfirm() };
            line.appendChild(buttonOk);
        }    
        confirmBox.appendChild(line);
    
        const confirmContainer = document.createElement("div");
        confirmContainer.id = "confirmContainer";
        confirmContainer.className = "confirmContainer";
        confirmContainer.appendChild(confirmBox);    
    
        const parentElement = document.getElementById("root");
        if (parentElement) parentElement.appendChild(confirmContainer);
    
        document.addEventListener("keydown", handleKeyDown);

    } catch(e) {
        console.log(e);
    }   
}

export default Confirm;

