import React from "react";

import { Button } from "./fields";

const Content = (props) => {
    const { state, reset } = props;
    return (
        <>        
            <h3>{state.error ? state.error.toString() : "ERROR"}</h3>
            <details>
                {state.errorInfo.componentStack}
            </details> 
            {reset && (<Button label="RESET" onClick={reset} />)}
        </>
    )
}

class ErrorElement extends React.Component {
    state = {
        error: null,
        errorInfo: null
    }
    
    componentDidCatch(error, errorInfo) {
        this.setState({ error, errorInfo });
    }

    reset = () => {
        this.setState({ error: null, errorInfo: null });
    }

    render() {
        const { className, style, sub, full, line } = this.props;

        let classe = "elementContainer";
        if (className) classe = className;
        let st = {};
        if (style) st = Object.assign({}, style);
        if (full) st.width = "-webkit-fill-available";

        if (this.state.errorInfo) {     
            if (sub) return (<Content state={this.state} reset={this.reset} />);
            else if (line) return (
                <line style={st}>
                    <Content state={this.state} />
                </line>
            )
            else return (
                <div className={classe} style={st}>
                    <Content state={this.state} reset={this.reset} />
                </div>
            )  
        } else return this.props.children;
    }
}

export default ErrorElement;