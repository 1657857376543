import React from "react";

import Popup from "./popup";
import Confirm from "./confirm";
import { checkPassword, request } from "./functions";
import * as IS from "./validator";

import loading_img from "./loading.png";

const voidf = () => {};
const inputFields = ["text", "ip", "url", "cron", "objectId", "stringa", "string", "email", "textarea", "hidden"];
const numberFields = ["port", "gpio", "statusCode", "number"];

/**
 * React Component to display INPUT LABEL
 * @param {string} label - Label Text
 * @param {string} name - Input Name, used as text if no label given
 * @param {string} description - If given create Input description info
 * @param {string} divClass - If given set DIV class
 * @param {object} divStyle - If given set the DIV style 
 * @param {string} spanClass - If given set SPAN class
 * @param {object} spanStyle - If given set the SPAN style
 * @param {function} onClick - If given add onClick listener to SPAN
 * @returns 
 */
const Label = (props) => {
    const { label, name, description, divClass, divStyle, spanClass, spanStyle, onClick } = props;

    function clickInfo(event) {
        try {
            const { previousElementSibling } = event.target;
            if (previousElementSibling) {
                if (previousElementSibling.style.display === "none") previousElementSibling.style.display = "";
                else previousElementSibling.style.display = "none";
            }
        } catch(e) {
            console.log(e);
        }
    }

    function closeInfo(event) {
        try {
            event.target.style.display = "none";            
        } catch(e) {
            console.log(e);
        }
    }

    let text = "no label";
    if (IS.string(label)) text = label;
    else if (IS.string(name)) text = name;

    return (        
        <div className={IS.string(divClass) ? divClass : ""} style={divStyle}>
            {IS.string(description) && (
                <React.Fragment>
                    <p className="labelDescription" style={{ display: "none" }} onClick={closeInfo}>
                        {description}
                    </p>
                    <a onClick={clickInfo}>&#9432;</a>
                </React.Fragment>
            )} 
            <span 
                style={spanStyle}
                className={IS.string(spanClass) ? spanClass : ""}
                onClick={IS.func(onClick) ? onClick : voidf}
            >
                {text}
            </span> 
        </div>  
    )
}
/**
 * Display a Boolean Select Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {string} props.label 
 * @param {*} props.style 
 * @param {boolean} props.booleanShow 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {boolean} props.disabled 
 * @param {boolean} props.column 
 * @param {boolean} props.cliccable
 * 
 */
export const BooleanField = (props) => {
    const { 
        name, value, onChange, label, style, noSpan,  
        spanStyle, divStyle, disabled, description, column 
    } = props;

    function handleClick(event) {
        try {
            if (!disabled) {
                if (IS.func(onChange)) onChange({ target: { name: name, type: "hidden", value: !value }});
            }
        } catch(e) {
            console.log(e);
        }
    }

    let dStyle = {};
    if (column) dStyle.flexDirection = "column-reverse";
    if (IS.object(divStyle)) dStyle = { ...dStyle, ...divStyle };

    return (
        <div className={column ? "inputContainer" : "inputContainer inLine"} style={dStyle}>
            <div className={value ? "selectBox selected" : "selectBox"} onClick={handleClick} style={style} />  
            {!noSpan && (
                <Label 
                    label={label}
                    name={name}
                    description={description}
                    onClick={handleClick}
                    spanClass="cliccableSpan"
                    spanStyle={spanStyle}
                    divStyle={{ width: column ? "100%" : "unset" }}
                /> 
            )}                  
            <input type="hidden" name={name} value={value} />
        </div> 
    ) 
}
/**
 * Display a Generic Input Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {string} props.type - Default Text
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {boolean} props.disabled 
 * @param {*} props.style 
 * @param {boolean} props.inLine 
 * @param {boolean} props.noSpan 
 * @param {string} props.label 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {number} props.maxlength
 * @param {string} props.pattern - REGEX Expression
 * @param {number} props.size 
 * @param {string} props.placeholder 
 * 
 */
export const InputField = (props) => {
    const { 
        name, label, type, value, onChange, inLine, pattern,  
        noSpan, divStyle, size, uppercase, lowercase, ...other 
    } = props;

    function change(event) {
        if (uppercase && event.target.value) event.target.value = event.target.value.toUpperCase();
        if (lowercase && event.target.value) event.target.value = event.target.value.toLowerCase();
        if (IS.regex(pattern) && IS.string(event.target.value)) {
            if (!pattern.test(event.target.value)) event.target.value = value;
        }
        if (IS.string(type) && typeof IS[type] === "function") {
            if (IS[type](event.target.value)) event.target.style.borderColor = "green";
            else event.target.style.borderColor = "red";            
        }
        if (onChange) onChange({ target: { name: name, value: event.target.value, type: type }});
    }

    let newType = type;
    if (IS.object(value)) newType = "textarea";
    else if (IS.array(value)) newType = "textarea";
    else if (IS.error(value)) newType = "text";
    else if (typeof value === "function") newType = "text";

    let newValue = value;
    if (IS.object(value)) newValue = JSON.stringify(value);
    else if (IS.array(value)) newValue = value.toString();
    else if (IS.error(value)) newValue = value.message;
    else if (typeof value === "function") newValue = "-- function --";

    let dinamicSize = IS.number(size) ? size : 20;
    if (IS.string(newValue) && dinamicSize < (newValue.length + 2)) dinamicSize = newValue.length + 2;
    if (!IS.number(size) && dinamicSize < 20) dinamicSize = 20;

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && ( <Label label={label} name={name} { ...other } /> )}
            {(IS.string(newType) && newType === "textarea") ? (
                <textarea 
                    name={name}
                    value={newValue}
                    onChange={onChange || voidf}
                    {...other}
                />
            ) : (
                <input 
                    name={name}
                    value={newValue} 
                    type={newType}
                    onChange={change}
                    size={dinamicSize}
                    {...other}
                />
            )}
        </div>  
    )
}
/**
 * Display a Dropdown Select Field
 * @param {Object} props 
 * @param {string} props.name 
 * @param {*} props.value
 * @param {Function} props.onChange 
 * @param {*} props.options 
 * @param {*} props.style  
 * @param {boolean} props.inLine 
 * @param {string} props.label 
 * @param {string} props.type
 * @param {boolean} props.noVoid 
 * @param {*} props.spanStyle 
 * @param {*} props.divStyle 
 * @param {boolean} props.disabled 
 * @param {boolean} props.noSpan 
 * 
 */
export const SelectField = (props) => {
    const { 
        name, label, type, value, onChange, options, inLine, 
        divStyle, disabled, noSpan, noVoid, ...other
    } = props;

    function change(event) {
        if (!disabled) {
            let newValue = event.target.value; 
            if (IS.string(type) && type === "number") newValue = Number(newValue);
            if (onChange) onChange({ target: { value: newValue, name: event.target.name, type: type } });
        }
    }

    let opts = [];
    if (IS.array(options)) {
        options.forEach((el) => {
            if (IS.object(el) && typeof el.value !== "undefined" && typeof el.label !== "undefined") opts.push({ value: el.value, label: el.label }); 
            else if (!IS.object(el)) opts.push({ value: el, label: el })
        })
    } else if (IS.object(options)) {
        Object.entries(options).forEach(([key, val]) => {
            opts.push({ value: val, label: key })
        })
    } 

    let dis = disabled;
    if (opts.length === 0) dis = true;
    if (!noVoid) opts.unshift({ value: "", label: "" });

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<Label name={name} label={label} { ...other } />)}
            <select name={name} value={value} onChange={change} disabled={dis} {...other}>               
                {opts.map((opt) => (
                    <option value={opt.value}>{opt.label}</option>
                ))}                
            </select>
        </div> 
    )
}

export const NumberField = (props) => {
    const { 
        name, value, type, onChange, label, divStyle, decimals, 
        disabled, inputClass, inLine, noSpan, noButton, ...other
    } = props; 
    
    function handleSub(event) {
        try {
            if (!disabled) {
                let inputField = event.target.parentElement.getElementsByTagName("input")[0].cloneNode(true);
                inputField.value = Number(inputField.value) - Number(inputField.step || 1);
                if (onChange) change({ target: inputField });
            }    
        } catch(e) {
            console.log(e);
        }      
    }
    function handleAdd(event) {
        try {
            if (!disabled) {
                let inputField = event.target.parentElement.getElementsByTagName("input")[0].cloneNode(true);
                inputField.value = Number(inputField.value) + Number(inputField.step || 1);
                if (onChange) change({ target: inputField });
            }    
        } catch(e) {
            console.log(e);
        }  
    }
    function change(event) {
        if (!disabled) {  
            let newValue = Number(event.target.value); 
            if (event.target.max && (newValue > Number(event.target.max))) newValue = Number(event.target.max);
            if (event.target.min && (newValue < Number(event.target.min))) newValue = Number(event.target.min);
            if (IS.number(decimals)) newValue = Number(Number(newValue).toFixed(decimals));
            if (onChange) onChange({ target: { value: newValue, name: name, type: type } });
        }
    }

    let params = {
        type: "number",
        name: name,
        value: value
    }
    if (IS.string(type) && type === "gpio") {
        params.min = 0;
        params.max = 27;
    }
    if (IS.number(decimals)) params.value = Number(value).toFixed(decimals);

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<Label name={name} label={label} { ...other } />)}
            <div className="numberInputContainer">
                {!noButton && (<button onClick={handleSub}>-</button>)}
                <input  
                    onChange={change}
                    disabled={disabled}
                    {...params}
                    {...other}
                />
                {!noButton && (<button onClick={handleAdd}>+</button>)}
            </div>
        </div> 
    )
}

export const TimeField = (props) => {
    const { name, value, onChange, disabled, style, inLine, label, divStyle, noSpan, ...other } = props;
    
    let date = "";
    let dateObj = new Date(IS.string(value) ? value : "");
    if (!isNaN(dateObj)) {
        try {
            const iso = dateObj.toISOString();
            date = iso.split("T")[0];
        } catch(e) {
            console.log(e);
        }
    }
    
    function change(event) {
        const { value } = event.target;
        if (!disabled && onChange) {
            let newDate = new Date(value);
            onChange({ target: { 
                name: name, 
                value: isNaN(newDate) ? null : newDate.toISOString()
            }})
        }
    }

    return (
        <div className={inLine ? "inputContainer inLine" : "inputContainer"} style={divStyle}>
            {!noSpan && (<Label name={name} label={label} { ...other } />)}
            <input 
                type="date"
                value={date}
                onChange={change}
                readOnly={disabled}
                style={style}
            />             
        </div>  
    )
}

export const FileField = (props) => {
    const { name, label, accept, onChange, divStyle, disabled, button, onClick } = props;

    function click(event) {
        if (!disabled) event.target.previousElementSibling.click();
    }
    function change(event) {
        if (!disabled && onChange) {
            if (event.target.files[0]) {
                if (accept && (accept === ".json")) {
                    try {                            
                        const reader = new FileReader();
                        reader.onload = (evt) => {
                            const json = JSON.parse(evt.target.result);
                            onChange({ target: { name: name, value: json }});
                        }                    
                        reader.readAsText(event.target.files[0]); 
                    } catch (err) {
                        console.log(err);
                    }
                } else {
                    try {                        
                        const url = URL.createObjectURL(event.target.files[0]);
                        onChange({ target: { name: name, value: url }});
                    } catch (err) {
                        console.log(err);                        
                    }
                }                   
            }
        }
    }
    function clickBtn(event) {
        if (!disabled && onClick) {
            const inputField = event.target.parentElement.getElementsByTagName("input")[0];
            if (inputField.files.length > 0) onClick({ target: { name: name, files: inputField.files }});
        }
    }

    return (
        <div className="inputContainer inLine" style={divStyle || { justifyContent: "center" }}>
            <input type="file" accept={accept} style={{ display: "none" }} onChange={change} />
            <button className="fileButton" onClick={click}>{label || accept}</button>
            {button && (<button onClick={clickBtn}>{button}</button>)}
        </div>  
    )
}

export const Button = (props) => {
    const { name, label, value, onClick, onChange, divStyle, style, disabled, selected, img } = props;

    function click(event) {
        if (onClick || onChange) {
            event.target.classList.add("bounceButton");
            event.target.addEventListener("animationend", () => { event.target.classList.remove("bounceButton") });
            if (onClick) onClick(event);            
            if (onChange && name) {
                let ev = { target: { name: name, type: typeof value }};
                if (IS.boolean(value)) ev.target.value = !value;
                else ev.target.value = value;                
                onChange(ev);
            }
        }        
    }

    let disp = "-";
    if (IS.string(label)) disp = label;
    else if (IS.string(name)) disp = name;
    else if (typeof value !== "undefined") disp = `${value}`;

    let st = {};
    if (IS.object(style)) st = { ...style };
    if (selected) st = { ...st, background: "var(--btColorFull)" };

    return (
        <div className="inputContainer" style={divStyle}>
            <button onClick={click} value={value} style={st} disabled={disabled}>
                {IS.string(img) ? (
                    <img src={img} alt={disp} />
                ) : (
                    <React.Fragment>
                        {disp}
                    </React.Fragment>
                )}
            </button>
        </div>
    )
}

class NewPassword extends React.Component {
    state = {
        password: "",
        repeat: "",
        strength: {
            length: false,
            uppercase: false,
            lowercase: false,
            special: false,
            number: false
        },
        label: "NEW PASSWORD",
        label2: "REPEAT PASSWORD",
        disabled: true
    }

    constructor(props) {
        super(props);
        if (props.label) this.state.label = props.label;
        if (props.label2) this.state.label2 = props.label2;
    }
    
    handleNew = (event) => {
        const { value } = event.target;
        const { onChange, name } = this.props;
        const strength = checkPassword(event);
        if (strength.length && strength.uppercase && strength.lowercase && strength.special && strength.number) {
            this.setState({ [event.target.name]: value, strength: strength, disabled: false }); 
            event.target.style.borderColor = "green";
        } else {
            this.setState({ [event.target.name]: value, strength: strength, disabled: true }); 
            if (onChange) onChange({ target: { name: name, value: "" }});
            event.target.style.borderColor = "red";
        }
    }

    handleRepeat = (event) => {
        const { value } = event.target;
        const { password } = this.state;
        const { onChange, name } = this.props;
        this.setState({ [event.target.name]: value });
        if (value === password) {
            event.target.style.borderColor = "green";
            if (onChange) onChange({ target: { name: name, value: password }});
        } else {
            event.target.style.borderColor = "red";
            if (onChange) onChange({ target: { name: name, value: "" }});
        }        
    }

    render() {
        const { password, repeat, strength, label, label2, disabled } = this.state;

        return (
            <React.Fragment>
                <div className="inputContainer">
                    <span>{label}</span>
                    <input type="password" value={password} name="password" onChange={this.handleNew} />
                </div>          
                <div className="passwordStrength">
                    <span className={strength.length ? "passed" : ""}>Lunghezza almeno 10 caratteri</span>
                    <span className={strength.uppercase ? "passed" : ""}>Almeno una lettera Maiuscola</span>
                    <span className={strength.lowercase ? "passed" : ""}>Almeno una lettera Minuscola</span>
                    <span className={strength.special ? "passed" : ""}>Almeno un carattere Speciale</span>
                    <span className={strength.number ? "passed" : ""}>Almeno un Numero</span>
                </div>  
                <div className="inputContainer">
                    <span>{label2}</span>
                    <input type="password" value={repeat} name="repeat" onChange={this.handleRepeat} disabled={disabled} />
                </div> 
            </React.Fragment>
        )
    }
}
export { NewPassword };

export const Field = (props) => {
    const { field, options, ...common } = props;

    if (IS.array(options) || IS.object(options)) return (
        <SelectField options={options} {...common} />
    ) 
    else if (IS.object(field) && (IS.array(field.options) || IS.object(field.options))) return (
        <SelectField {...common} {...field} />
    )
    else {
        let params = { type: "undefined" };
        if (IS.object(field)) params = Object.assign({}, field);
        if (IS.string(field)) params.type = field;
        else if (IS.object(field) && IS.string(field.type)) params.type = field.type;
        else if (typeof field !== "undefined") params.type = typeof field;
        else if (IS.string(props.type)) params.type = props.type;
        else params.type = typeof props.value;
        if (inputFields.includes(params.type)) return (
            <InputField {...common} {...params} />
        )
        else if (numberFields.includes(params.type)) return (
            <NumberField {...common} {...params} />
        )
        else if (params.type === "file") return (
            <FileField {...common} {...params} />
        )
        else if (params.type === "boolean") return (
            <BooleanField {...common} {...params} />
        )
        else if (params.type === "time") return (
            <TimeField {...common} {...params} />
        )
        else if (params.type === "newPassword") return (
            <NewPassword {...common} {...params} />
        )
        else return (
            <InputField {...common} {...params} />
        )          
    }
}

export const Loading = (props) => {
    const { className, img, style } = props;

    if (img) return (
        <img src={loading_img} alt="loading" className="spin" style={style} />        
    )
    else if (className) return (
        <div className={className} style={style}>
            <div className="loadingContainer" >
                <img src={loading_img} alt="loading" className="spin" />
            </div>
        </div>
    )
    else return (
        <div className="loadingContainer" style={style} >
            <img src={loading_img} alt="loading" className="spin" />
        </div>
    )
}

export const NoItems = (props) => {
    const { className, text } = props;
    let css = "commandContainer";
    if (className) css = className;
    return (
        <div className={css}>
            <h1>{text || "... NESSUN ELEMENTO TROVATO ..."}</h1>
        </div>
    )
}

export function getVal(obj, path) {
    try {
        const value = path.replace(/\[([^\[\]]*)\]/g, '.$1.').split('.').filter(t => t !== '').reduce((prev, cur) => prev && prev[cur], obj)
        return value;
    } catch(e) {
        return e.message;
    }
}

export function setProperty(obj, path, value) {
    const [head, ...rest] = path.split('.');
    return {
        ...obj,
        [head]: rest.length
            ? setProperty(obj[head], rest.join('.'), value)
            : value
    }
}

export function handleChange(event, state) {
    const { name, value, type } = event.target;
    let newValue = value;
    if (type === "number") newValue = Number(value);  
    else if ((type === "hidden")&&(value === "true")) newValue = true;
    else if ((type === "hidden")&&(value === "false")) newValue = false;
    if (name.includes(".")) {
        const parentPath = name.substring(0, name.indexOf("."));
        const childPath = name.substring(name.indexOf(".") + 1);
        return ({ [parentPath]: setProperty(state[parentPath], childPath, newValue) }); 
    } else return ({ [name]: newValue });               
}   

export function loadOptions(type) {
    return new Promise(async (resolve, reject) => {
        try {
            let api = "";
            let body;
            if (type === "collection") api = "database/listCollections";
            else if (type === "evento") {
                api = "database/getAllDocuments";
                body = { collection: "eventi" };
            } else if (type === "driver") api = "public/getDrivers";
            const r = await request(api, body);
            if (r.statusCode === 200) resolve(Array.from(r.body).map(e => e.name));
            else if (r.statusCode === 202) resolve([]);
            else reject(new Error(r.result));
        } catch(e) {
            reject(new Error(e.message));
        }
    })
}

export function populateYears(n) {
    try {
        let num = 10;
        if (IS.number(n)) num = n;
        const date = new Date();
        const year = date.getFullYear();    
        let options = []; 
        for (let i=0; i<=num; i++) {
            options.push(year - i);
        }
        return options;
    } catch(e) {
        console.log(e);
        return ([])
    }
}

class FileBlock extends React.Component {
    state = {
        nomeDati: "",
        folder: "",
        files: [],
        upload: true,
        filename: "",
        anno: null,
        options: [],
        loading: false
    }

    constructor(props) {
        super(props);
        if (props.folder) this.state.folder = props.folder;
        if (props.nomeDati) this.state.nomeDati = props.nomeDati;
        if (props.noUpload) this.state.upload = false;
        if (props.filename) this.state.filename = props.filename; 
        if (props.anno) this.state.anno = new Date().getFullYear();       
        if (IS.array(props.options, true)) {
            this.state.options = props.options; 
            this.state.filename = props.options[0];      
        }
    }

    componentDidMount() {
        this.loadItems();
    }

    loadItems = async () => {
        try {
            const { nomeDati, folder } = this.state;
            this.setState({ loading: true, files: [] });
            const r = await request("files/getFiles", { nomeDati: nomeDati, folder: folder }); 
            if (r.statusCode === 200) this.setState({ files: r.body, loading: false });
            else {
                if (r.statusCode !== 202) Popup(r);
                this.setState({ loading: false });
            } 
        } catch(e) {
            Popup(e);
            this.setState({ loading: false });
        }
    }

    downloadFile = (file) => {
        const { folder } = this.state;
        Confirm(`Scaricare ${file} da ${folder} ?`, async (confirm) => {
            if (confirm) {
                try {
                    this.setState({ loading: true });
                    const r = await request(`public/download?file=${file}&folder=${folder}`, "GET", "blob");  
                    if (r.objectURL) {                       
                        const a = document.createElement("a");
                        a.setAttribute("href", r.objectURL);
                        a.setAttribute("download", file);
                        a.style.display = "none";     
                        document.body.appendChild(a);
                        a.click();
                        document.body.removeChild(a);
                        URL.revokeObjectURL(r.objectURL);
                        this.setState({ loading: false });
                    } else {
                        Popup(r);
                        this.setState({ loading: false });
                    }
                } catch(e) {
                    Popup(e);
                    this.setState({ loading: false });
                }
            }    
        })
    }

    deleteFile = (file) => {
        const { folder } = this.state;
        Confirm(`Eliminare ${file} da ${folder} ?`, async (confirm) => {
            if (confirm) {
                try {
                    this.setState({ loading: true });
                    const r = await request("files/deleteFile", { folder: folder, file: file });
                    if (r.statusCode === 200) this.loadItems();
                    else {
                        Popup(r);
                        this.setState({ loading: false });
                    }
                } catch(e) {
                    Popup(e);
                    this.setState({ loading: false });
                }
            }    
        })
    }

    uploadFile = (event) => {
        const { nomeDati, folder, filename, anno } = this.state;
        const input = event.target.previousElementSibling;
        if (input && input.files[0]) {
            let fname = `${nomeDati}`;
            if (IS.string(filename)) fname += `_${filename}`;
            if (IS.number(anno)) fname += `_${anno}`;
            fname += `.${input.files[0].type.split("/")[1]}`;
            const formData = new FormData();
            formData.append("folder", folder);
            formData.append("file", input.files[0], fname);
            Confirm(`Caricare ${fname} in ${folder} ?`, async (confirm) => {
                if (confirm) {
                    try {
                        this.setState({ loading: true });
                        const r = await request("files/uploadFile", formData);
                        if (r.statusCode === 200) {
                            input.value = "";
                            this.loadItems();
                        } else {
                            Popup(r);
                            this.setState({ loading: false }); 
                        }                       
                    } catch(e) {
                        Popup(e);
                        this.setState({ loading: false });
                    }
                }               
            })
        }
    }

    render() {
        const { accept, label } = this.props;
        const { filename, nomeDati, folder, files, upload, anno, options, loading } = this.state;

        return (
            <div className="borderBlock">
                <h2 style={{ textTransform: "uppercase" }}>{IS.string(label) ? label : nomeDati}</h2>
                {loading ? (
                     <img src={loading_img} alt="loading" className="spin" />
                ) : (
                    <React.Fragment>     
                        {upload && (
                            <line style={{ flexWrap: "wrap" }}>
                                <input 
                                    type="text"
                                    value={nomeDati}
                                    style={{ margin: "0.5em" }}
                                    disabled
                                />
                                {IS.array(options, true) ? (
                                    <SelectField 
                                        name="filename"
                                        value={filename}
                                        options={options}
                                        onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
                                        noVoid
                                        noSpan
                                    />
                                ) : (
                                    <input 
                                        type="text"
                                        name="filename"
                                        placeholder="... Nome File ..."
                                        value={filename}
                                        onChange={(event) => this.setState({ [event.target.name]: event.target.value })}
                                        style={{ margin: "0.5em" }}
                                    />
                                )}
                                {IS.number(anno) && (
                                    <SelectField 
                                        name="anno"
                                        value={anno}
                                        options={populateYears()}
                                        onChange={(event) => this.setState({ [event.target.name]: Number(event.target.value) })}
                                        noVoid
                                        noSpan
                                    />
                                )}
                                <button onClick={(event) => { event.target.nextElementSibling.click() }} style={{ margin: "0.5em" }}>
                                    UPLOAD FILE
                                </button>
                                <input 
                                    type="file" 
                                    accept={IS.string(accept) ? accept : "*"}
                                    style={{ display: "none" }} 
                                    onChange={(event) => {
                                        if (event.target.files[0]) event.target.nextElementSibling.style.display = "";
                                        else event.target.nextElementSibling.style.display = "none";
                                    }}                                       
                                />
                                <button onClick={this.uploadFile} style={{ margin: "0.5em", display: "none" }}>CARICA</button>
                            </line>
                        )} 
                        {IS.array(files, true) && (
                            <line style={{ flexWrap: "wrap" }}>
                                {files.map((el) => (
                                    <div className="elemBlock" key={`${folder}.${el}`} style={{ height: "7em", minWidth: "fit-content" }}>
                                        <span>{el}</span>
                                        <line style={{ padding: "unset", justifyContent: "space-evenly", fontSize: "0.8em" }}>
                                            <Button label="SHOW" onClick={() => this.downloadFile(el)} />
                                            <Button label="DELETE" onClick={() => this.deleteFile(el)} />
                                        </line>
                                    </div>
                                ))}
                            </line>
                        )}                        
                    </React.Fragment>
                )}  
            </div>
        )
    }
}

export { FileBlock };
